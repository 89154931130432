module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.getwindstream.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-NJ9PQ2","brandTokens":["WDS","WDSB","TND"],"mapiBrandToken":"WDS","siteName":"getwindstream","alternateName":"getwindstream.com","siteURL":"https://www.getwindstream.com","defaultTitleTemplate":"","defaultPhone":"8338151183","phoneSymbol":"-","defaultPromoCode":"45807","removeTrailingSlashes":true,"smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046062","vwoProjectID":"894940","cobra":{"sitePath":"getwindstream.com","buttonLink":"https://shop.getwindstream.com/?kbid={PROMO_CODE}&clreqid={REQUEST_ID}","buttonText":"Order Online","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"getwindstream","short_name":"getwindstream","start_url":"/","background_color":"#22a881","theme_color":"#22a881","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6c5f5ab9228ea3ca4b03617253e5075"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"rmk3sth"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
