import React from 'react'
import { withTheme } from 'emotion-theming'
import PackageCardStyles from './PackageCard.styles'
import LeshenColumns from './LeshenColumns.styles'
import LeshenSplitModule from './LeshenSplitModule.styles'

const GlobalStyles = withTheme(() => (
  <>
    <PackageCardStyles />
    <LeshenColumns />
    <LeshenSplitModule />
  </>
))

export default GlobalStyles
