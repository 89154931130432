import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const LeshenSplitModule = withTheme(() => (
  <Global
    styles={css`
      .leshen-split-module {
        .module-area {
          .leshen-stack {
            align-items: unset;
          }
        }
      }
    `}
  />
))

export default LeshenSplitModule
