import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-package-card {
        .wrapper {
          box-shadow: 0 0.3rem 1rem 0 rgb(0 0 0 / 10%);
        }

        *:not(button) {
          color: black;
        }

        .wrapper {
          .title {
            text-transform: unset !important;
            margin-bottom: 10px;
          }

          .modal {
            & > span {
              color: #000 !important;
            }
          }

          .leshen-price-disclaimers-wrapper {
            * {
              color: #000 !important;
            }
          }
        }

        .list-item {
          p {
            * {
              color: #000 !important;
            }
          }
        }

        .details {
          background-color: transparent !important;
          padding-top: 0 !important;
          margin-bottom: 0 !important;

          .wrapper-detail {
            justify-content: flex-start;
            font-size: 14px !important;
            padding-top: 0;
            padding-left: 16px;
          }
        }

        .leshen-link-button {
          svg {
            margin: 0;
          }
        }
      }
    `}
  />
))

export default PackageCardStyles
